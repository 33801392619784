import { UserInfo } from 'firebase/auth';
import type { Timestamp } from 'firebase/firestore';
import type { FileNode } from 'gatsby-plugin-image/dist/src/components/hooks';
import { QuestionSection } from './survey/questions';

export type Gender = 'male' | 'female' | 'non-binary' | 'unsure';

export type UserStage = 'survey' | 'topic' | 'followup';

export type UserCategory = 'participant' | 'other';

export type Progress = {
  total: number;
  completed: number;
};

export type TopicPlanItem = {
  topicId: string;
  seq: number;
  mandatory: boolean;
  tag?: string;
  pin?: boolean;
};

export namespace state {
  export type User = {
    userCategory: UserCategory;
    userYob: string;
    userFirstname: string;
    userSurname: string;
    userContactAgreement: boolean;
    userConsent: boolean;
    // userAge: string;
    userGender?: string;
    userPostcode?: string;
    // userPhone: string;
    // userPhoneAlt?: string;
    userEducation: string;
    userWorkStatus?: string;
    userNeurodivergent?: string;
    //userContactEmail?: string;
    familyEthnicity: string;
    familyRelationship: string;
    familyLanguage: string;
    // familyIncome?: string;
    // familyCovidImpact?: string[];
    childName: string;
    childSurname: string;
    childDob: string;
    childLivingWith: string;
    childFirstLanguageEnglish?: string;
    childEnglishProficiency?: string;
    childOtherLanguages?: string;
    childActivity: string;
    childsSignificantLifeEvents?: string;
    childDiagnosis: string;
    childMedicalCondition: string;
    // childGrade: number;
    childGender?: string;
    childPronouns: Gender;
    childRelationship: string;
    emailNotification: boolean;
    smsNotification: boolean;
    // childOtherParentsJoined: string;
    // discoverChannels?: string;
    // discoverReason?: string;
    // discoverFutureResearch?: string;
    currentStage?: UserStage;
    createdAt?: Timestamp;
    topicPlanningCompletedAt?: Timestamp;
    topicSuggestion?: string[];
    surveyCompletedAt?: Timestamp;
    followupUnlockAt?: Timestamp;
    followupUnlockedAt?: Timestamp;
    followupCompletedAt?: Timestamp;
    topicCompletedAt?: Timestamp;
    optOutAt?: Timestamp;
    slug?: string;
  } & Partial<UserInfo>;

  export type Survey = {
    _totalRequired: number;
    _completedRequired: number;
    _totalOptional: number;
    _completedOptional: number;
    _step: number;
  } & Record<string, any>;

  export type TopicEntry = {
    _visited?: { seconds: number; nanoseconds: number };
  } & { [contentKey: string]: any };

  export type Goals = Record<string, { added: Timestamp; completed?: Timestamp }>;

  export type Topic = {
    mandatory: boolean;
    locked: boolean;
    seq: number;
    tag?: string;
    progress?: Progress;
    goals?: Goals;
    lastVisited?: string;
    entries: Record<string, TopicEntry>;
  };
}

export namespace data {
  export type Topic = {
    key: string;
    seq: number;
    title: string;
    description: string;
    cover: {
      localFile: FileNode;
    };
    contents: Array<Omit<Content, 'body'>>;
  };

  export type TopicWithActivityKeys = {
    slug: string;
    contents: Array<Pick<Content, 'activityKeys' | 'optional'> & { slug: string }>;
  };

  type ActivityKeys = {
    data: string[];
  };

  export type Content = {
    key: string;
    seq: number;
    title: string;
    optional: boolean | null;
    goals: boolean | null;
    activityKeys: ActivityKeys | null;
    body: {
      data: {
        childMdx: {
          body: string;
        };
      };
    };
  };

  type ExtraStyle = Record<string, string | number>;

  export type Survey = {
    key: string;
    scope: 'baseline' | 'followup';
    title: string;
    tint: string;
    cover: {
      localFile: {
        publicURL: string;
      };
    };
    extraStyle?: ExtraStyle;
    sections: {
      data: Array<QuestionSection>;
    };
  };
}

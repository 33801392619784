import { navigate } from 'gatsby';
import { Box, Button, Card, CardHeader, CardContent } from '@mui/material';
import { Feedback } from 'app/feedback';

import type { state, data } from 'app';
import { useEffect } from 'react';

export type SurveyFeedbackProps = {
  survey: data.Survey;
  user: state.User;
  userSurvey: state.Survey;
  updateUserSurvey: (data: Partial<state.Survey>) => void;
  nextId: string | null;
  prevId: string | null;
};

export function SurveyFeedback(props: SurveyFeedbackProps) {
  const { survey, user, userSurvey, prevId, nextId, updateUserSurvey } = props;
  const prevDisabled = !prevId;
  const surveyDebug = process.env.GATSBY_SURVEY_DEBUG || false;
  useEffect(() => {
    document.querySelector('main')?.scrollTo({ top: 0 });
  }, []);

  const handleRestart = () => {
    updateUserSurvey({ _step: 0 });
  };

  return (
    <Box pb={4}>
      <Card sx={{ mt: 4 }}>
        <CardHeader title="Feedback" />
        <CardContent>
          <Feedback surveyId={survey.key} user={user} userSurvey={userSurvey} />
        </CardContent>

        <Box mt={2} p={2} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/surveys/${prevId}`)}
            disabled={prevDisabled}
            aria-disabled={prevDisabled}>
            Prev
          </Button>
          {/* {surveyDebug && (
            <Button variant="contained" color="primary" onClick={handleRestart}>
              Redo Survey
            </Button>
          )} */}
          {nextId ? (
            <Button
              color="primary"
              // href={`/surveys/${nextId}`}
              onClick={() => navigate(`/surveys/${nextId}`)}>
              Next
            </Button>
          ) : (
            <Button
              color="primary"
              // href={`/dashboard#${survey.scope}`}
              onClick={() => navigate(`/dashboard#${survey.scope}`)}>
              {survey.scope === 'baseline' ? 'Continue to modules' : 'Back to dashboard'}
            </Button>
          )}
        </Box>
      </Card>
    </Box>
  );
}
